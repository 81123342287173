<template>
  <div class="batch-search">
    <div class="top-box">
              <div class="top-title">优惠码管理</div>
              <div class="btn-box">
                <div class="btn" @click="handleSearchClick()"><i class="el-icon-search"></i> 查询</div>
              </div>
            </div>
            <el-row class="form-group">
              <el-col :span="3" class="form-item">
                  <div class="label">优惠码</div>
                  <div class="input-box">
                      <el-input v-model="discountCode" placeholder="请输入优惠码" class="form-control" maxlength="6"></el-input>
                  </div>
              </el-col>
              <el-col :span="4" class="form-item">
                  <div class="label">所属账号</div> 
                  <div class="input-box">
                      <el-input v-model="belongDiscountCode" placeholder="请输入所属账号" class="form-control"></el-input>
                  </div>
              </el-col>
            </el-row>
    <div class="table-container-body">
      <el-table :data="tableData" height="660" stripe class="table-body"
      :header-cell-style="{background:'#e6e6e6', color: '#646464'}"
      v-loading="tableLoading">
        <el-table-column prop="id" label="序号" width="80"></el-table-column>
        <el-table-column prop="discountCode" label="优惠码"></el-table-column>
        <el-table-column prop="name" label="姓名"></el-table-column>
        <el-table-column prop="phone" label="电话"></el-table-column>
        <el-table-column prop="province" label="省份"></el-table-column>
        <el-table-column prop="cityName" label="城市"></el-table-column>
        <el-table-column prop="schoolName" label="院校"></el-table-column>
        <el-table-column prop="classGrade" label="班级"></el-table-column>
        <el-table-column prop="subject" label="选科"></el-table-column>
        <el-table-column prop="score" label="分数"></el-table-column>
      </el-table>
    </div>
    <div class="table-container-footer">
      <el-pagination
        layout="total, prev, pager, next, sizes, jumper"
        :page-sizes="[10, 50, 100, 500]"
        :page-size="pageSize"
        :total="tableTotal"
        :current-page="pageNo"
        @current-change="pageChange"
        @size-change="sizeChange"
      >
      </el-pagination>
    </div>
    <el-dialog
      title="修改分数"
      width="20%"
      :visible.sync="editDialogVisible"
      :before-close="handleAddClose"
    >
      <el-row>
        <el-col :span="20" class="add-col">
          <div class="add-label">
            <div class="required-label">*</div>
            分数
          </div>
          <div class="add-input-box">
            <el-input
              v-model="editScore"
              placeholder="请输入分数"
              class="form-control" 
            ></el-input>
          </div>
        </el-col>
      </el-row>

      <span slot="footer" class="dialog-footer">
        <div class="btn-box">
          <div class="btn-dialog-cancel" @click="editDialogVisible = false">关闭</div>
          <div class="btn-dialog-confirm" @click="handleUpdateSaveClick()">保存</div>
        </div>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: "batch",
  components: {},
  props: {
    msg: String,
  },
  data() {
    return {
      pageNo: 1,
      pageSize:10,
      tableTotal:0,
      tableData: [],
      discountCode:"",
      belongDiscountCode:"",
      editId:"",
      editScore:"",
      editTimes:0,
      tableLoading:false,
      editDialogVisible: false
    };
  },
  methods: {
    initSelectData(){
      
    },
    initTableData(){
      if(this.discountCode && (this.discountCode.length < 6 || !/^\d+(?=\.{0,1}\d+$|$)/.test(this.discountCode))){
        this.$message.error("优惠码格式不正确!请输入6位纯数字");
        return;
      }
      this.tableLoading=true;
      var data={
        currentPage: this.pageNo,
        pageSize: this.pageSize,
        discountCode: this.discountCode,
        discountCodeUser: this.belongDiscountCode,
      }
      //获取分页表格数据
      this.$http.post(this.$apis.getApplyStudentByCode,data).then((res) => {
        this.tableLoading=false;
        if (res.data) {
          this.tableData = res.data.list.map(r=>{
            return {
              id:r.id,
              year: r.year,
              phone: r.phone,
              cityName: r.cityName,
              province: r.provinceName,
              schoolName: r.schoolName,
              classGrade: r.classGrade,
              name:r.name,
              subject: r.course1+" "+r.course2+" "+r.course3,
              couldUpdateTimes: r.couldUpdateTimes,
              score: r.score,
              discountCode: r.discountCode
            }
          });
          this.tableTotal = res.data.total;
        }
      });
    },
    handleSearchClick(){
      this.pageNo = 1;
      this.initTableData();
    },
    handleUpdateClick(row){
      var adminInfo = this.$store.getters.getAdminInfo;
      if(adminInfo.adminType !=0 && (!row.couldUpdateTimes || row.couldUpdateTimes <=0)){
         this.$message.error("修改次数不足，无法修改！");
        return;
      }
      this.editDialogVisible = true;
      this.editId = row.id;
      this.editScore = row.score;
      this.editTimes =row.couldUpdateTimes;
    },
    handleUpdateSaveClick(){
       if(!this.editId || this.editId === ""){
         this.$message.error("账户不能为空");
        return;
      } if(!this.editScore || this.editScore === ""){
         this.$message.error("分数不能为空");
        return;
      }
      var updateTimes = this.editTimes - 1;
      var data ={
        id:this.editId,
        score:this.editScore,
        updateTimes:updateTimes < 0 ? 0 : updateTimes,
      };
      this.$http.post(this.$apis.updateStudentScore,data).then(res=>{
       if (res.code==200 && res.message==="SUCCESS") {
            this.$message.success("修改成功!");
        }
        else{
            this.$message.error("修改失败!原因:"+res.message);
        }
        this.editTimes = 0;
        this.editId = "";
        this.editScore = "";
        this.editDialogVisible = false;
        this.initTableData();
      });
    },
    handleStartClick(row){
      this.$router.push({
        path: "/back/applicationInfo",
        query:{
          studentId:row.id
        }
      });
    },
    handleStartApplicationClick(){

    },
    handleAddClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          this.editId = "";
          this.editScore = "";
          done();
        })
        .catch((_) => {});
    },
    pageChange(val) {
      this.pageNo = val;
      this.initTableData();
    },
    sizeChange(val){
      this.pageSize = val;
      this.initTableData();
    }
  },
  mounted(){
    this.initSelectData();
    this.initTableData();
  },
};
</script>
<style lang="less" scoped>
.batch-search {
  margin: 20px;
  width: calc(100% - 40px);
  height: calc(100% - 40px);
  border-radius: 5px;
  border: 1px solid rgba(230, 230, 230, 100);
  .add-col {
    padding: 15px 0px;
    display: flex;
    align-items: center;
    .add-label {
      display: flex;
      justify-content: flex-end;
      padding-right: 10px;
      width: 120px;
      .required-label {
        color: rgb(255, 0, 0);
      }
    }
    .add-input-box {
      display: flex;
      width: calc(100% - 100px);
    }
    .view-text-box{
      height: 40px;
      display: flex;
      align-items: center;
      width: calc(100% - 100px);
    }
  }
  .dialog-footer{
    display: flex;
    justify-content: flex-end;
    .btn-box {
      display: flex;
      align-items: center;
      .btn-dialog-cancel {
        line-height: 32px;
        width: 95px;
        border-radius: 4px;
        background-color: #fff;
        text-align: center;
        color: #000;
        margin-left: 15px;
        cursor: pointer;
        font-size: 14px;
        border: 1px solid #c9c9c9;
      }
      .btn-dialog-confirm {
        line-height: 32px;
        width: 95px;
        border-radius: 4px;
        background-color: rgba(255, 69, 11, 100);
        text-align: center;
        color: #fff;
        margin-left: 15px;
        cursor: pointer;
        font-size: 14px;
      }
    }
  }
  .top-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 13px 10px;
    border-bottom: 1px solid rgba(230, 230, 230, 100);

    .top-title {
      color: rgba(16, 16, 16, 100);
      font-size: 20px;
      font-weight: 700;
      padding-left: 20px;
      position: relative;
    }
    .top-title::after {
      position: absolute;
      display: block;
      content: "";
      top: 50%;
      left: 5px;
      transform: translateY(-50%);
      height: 27px;
      width: 3px;
      background-color: #ff450b;
    }
    .btn-box {
      display: flex;
      align-items: center;
      .btn {
        line-height: 32px;
        width: 95px;
        border-radius: 4px;
        background-color: rgba(255, 69, 11, 100);
        text-align: center;
        color: #fff;
        margin-left: 15px;
        cursor: pointer;
        font-size: 14px;
      }
      .btn-dialog-cancel {
        line-height: 32px;
        width: 95px;
        border-radius: 4px;
        background-color: #fff;
        text-align: center;
        color: #000;
        margin-left: 15px;
        cursor: pointer;
        font-size: 14px;
        border: 1px solid #c9c9c9;
      }
      .btn-dialog-confirm {
        line-height: 32px;
        width: 95px;
        border-radius: 4px;
        background-color: rgba(255, 69, 11, 100);
        text-align: center;
        color: #fff;
        margin-left: 15px;
        cursor: pointer;
        font-size: 14px;
      }
    }
  }
  .form-group{
      padding: 0px 20px;
    .form-item {
      display: flex;
      align-items: center;
      padding: 15px 0;
      .label {
        display: flex;
        justify-content: flex-end;
        color: rgba(100, 100, 100, 100);
        font-size: 14px;
        width: 70px;
        padding-right:10px;
      }
      .input-box {
          display: flex;
          width: calc(100% - 100px);
      }
      .required-label{
            color: rgb(255, 0, 0);
      }
      .action-link{
          font-size: 16px;
          cursor:pointer;
          color: #ff450b;
          padding-right: 20px;
      }
    }
  }
  .form-control{
      width: 100%;
  }
  .table-container-body {
    padding: 0 20px;
    .table-body{
      width: 100%;
      border: 1px solid #e6e6e6;
      overflow: auto;
    }
    .table-btn-box {
      display: flex;
      align-items: center;
      justify-content: space-around;
      .table-btn {
        cursor: pointer;
        width: 60px;
        line-height: 28px;
        border-radius: 14px;
        color: rgba(121, 160, 241, 100);
        font-size: 12px;
        text-align: center;
        border: 1px solid rgba(121, 160, 241, 100);
      }
    }
  }
  .table-container-footer{
    padding: 20px;
  }
  .bottom-box{
    padding: 20px;
    display: flex;
    justify-content: flex-end;
    .btn-box {
      padding: 0px 20px;
      display: flex;
      align-items: center;
      .btn {
        line-height: 32px;
        width: 95px;
        border-radius: 4px;
        background-color: rgba(255, 69, 11, 100);
        text-align: center;
        color: #fff;
        margin-left: 15px;
        cursor: pointer;
        font-size: 14px;
      }
    }
  }
}
</style>